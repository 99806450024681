import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import {getGlid, getGstData} from '../../utils';

import MainPage from '../pages/MainPage'
import AboutUsPage from '../pages/AboutUsPage';
import ProductsPage from '../pages/ProductsPage';
import OneInfLenderPage from '../pages/OneInfLenderPage'
import LoanTapLenderPage from '../pages/LoanTapLenderPage';
import IIFLLenderPage from '../pages/IIFLLenderPage';
import PoonawallaLenderPage from '../pages/PoonawallaLenderPage';
import IciciLenderPage from '../pages/IciciLenderPage';
import IndifiLenderPage from '../pages/IndifiLenderPage';
//import CreditEnableLenderPage from '../pages/CreditEnableLenderPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import TataCapitalPage from '../pages/TataCapitalPage';
import CRMComponent from '../crm/AuthComponent';

class RouterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            visited: sessionStorage.getItem('visited') ? true : false
        };

        if (!sessionStorage.getItem('visited')) {
            sessionStorage.setItem('visited', 'true');
        }

        var glid = getGlid();
        window.glid = glid;

        if (glid) {
            this.userCurrentStatus(glid);
        }
    }

    userCurrentStatus(glid) {

        var host = window.location.hostname;
        var statusUrl = host == 'loans.indiamart.com' ? 'https://loans.indiamart.com/api/v1/checkCurrentStatus' : 'https://dev-loans.indiamart.com/api/v1/checkCurrentStatus';

        var statusData = {
            "glid": glid,
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: statusUrl,
            data: JSON.stringify(statusData),
            timeout: 10000
        }).then((response) => {
            if (response['data'] == true) {
                this.setState({ flag: true });
            }
        }).catch(error => {
            console.error("Error occurred in callin checkCurrentStatus: ", error);
        })
    
}

async appendData(glid){
    var host = window.location.hostname;
    var url = host == "loans.indiamart.com"? "https://loans.indiamart.com/api/v1/getEncValue":"https://dev-loans.indiamart.com/api/v1/getEncValue";

    const formData = new FormData();
    formData.append('glid', glid);
  
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.data.CODE == '200'){
        console.log("Output from encVal => ", response.data.OUTPUT)
        var finalUrl = host=="loans.indiamart.com"?"https://www.hellotrade.com/apply"+"?encVal="+response.data.OUTPUT:"https://dev.hellotrade.com/apply"+"?encVal="+response.data.OUTPUT;
        window.location.href = finalUrl;
      } else{
        console.error("Error:", response.data)
      }
    } catch (error) {
      console.error('Error:', error);
    }
}

async componentDidMount() {
    var glid = getGlid();

if(glid == '195143359' || glid == '59002668' || glid == '13035342' || glid == '155069684' || glid == '132160368'){
  console.log("Setting hellotrade true => ", glid);
  this.appendData(glid);
}
}


    render() {
        return (
            <>
                <Router>
                    <div>
                        <Routes>
                            <Route path="/" element={!this.state.visited && this.state.flag ? <Navigate to="/applications" /> : <MainPage />} />
                            <Route path="/applications" element={<ApplicationsPage />} />
                            <Route path="/about" element={<AboutUsPage />} />
                            <Route path="/product" element={<ProductsPage />} />
                            <Route path="/partner/oneinfinity" element={<OneInfLenderPage />} />
                            {/* <Route path="/partner/loantap" element={<LoanTapLenderPage />} /> */}
                            <Route path="/partner/iifl" element={<IIFLLenderPage />} />
                            <Route path="/partner/icici" element={<IciciLenderPage />} />
                            {/* <Route path="/partner/poonawalla" element={<PoonawallaLenderPage />} /> */}
                            <Route path="/partner/indifi" element={<IndifiLenderPage />} />
                            {/* <Route path="/partner/creditenable" element={<CreditEnableLenderPage/>}/> */}
                            <Route path="/partner/tatacapital" element={<TataCapitalPage />} />
                            <Route path="/crm" element={<CRMComponent />} />

                        </Routes>
                    </div>
                </Router>
            </>
        );
    }

}

export default RouterComponent;
